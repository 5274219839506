import { Avatar, AvatarGroup, Box, Button, HStack } from '@chakra-ui/react'
import { FcDownload, FcPlus, FcUpLeft } from 'react-icons/fc'
import { useNavigate } from 'react-router-dom'
import React from 'react'

export const MindMapTopLeft = () => {
  const color= 'teal'
  const navigate = useNavigate()

  return(
    <Box>
      <HStack bgColor='white' shadow='base' p='2' borderRadius='full'>
        <FcUpLeft onClick={() => navigate(-1)}/>
        <AvatarGroup size='xs' max={4}>
          <Avatar name='Ryan Florence' src='https://bit.ly/ryan-florence' />
          <Avatar name='Segun Adebayo' src='https://bit.ly/sage-adebayo' />
          <Avatar name='Kent Dodds' src='https://bit.ly/kent-c-dodds' />
          <Avatar name='Prosper Otemuyiwa' src='https://bit.ly/prosper-baba' />
          <Avatar name='Christian Nwamba' src='https://bit.ly/code-beast' />
        </AvatarGroup>
        <FcPlus/>

        {/* <BackButton/> */}
        {/* <Button size='xs'colorScheme={color} onClick={()=>navigate('/mindmap/1')}>Main</Button>
            <Button size='xs'colorScheme={color} onClick={()=>navigate('/mindmap/1/basic')}>Basic</Button>
            <Button size='xs'colorScheme={color}  onClick={()=>navigate('/mindmap/1/auto')}>Auto</Button>
            <Button size='xs'colorScheme={color}  onClick={()=>navigate('/mindmap/1/collapse')}>collapse</Button>
            <Button size='xs'colorScheme={color}   onClick={()=>navigate('/mindmap/1/force')}>Force</Button>
            <Button size='xs'colorScheme={color}   onClick={()=>navigate('/mindmap/1/float')}>Float</Button>
            <Button size='xs'colorScheme={color}   onClick={()=>navigate('/mindmap/1/copy')}>Copy paste</Button>
            <Button size='xs'colorScheme={color}   onClick={()=>navigate('/mindmap/1/collab')}>Collab</Button>
            <Button size='xs'colorScheme={color}   onClick={()=>navigate('/mindmap/1/undo')}>Undo</Button> */}
        <FcDownload/>
      </HStack>
    </Box>
  )
}