import React from 'react';
import ExampleSolution1Page from '../Pages/Example/Solution1/ExampleSolution1Page';
import ExamplePage from '../Pages/Example/ExamplePage';
import ExampleSolution2Page from '../Pages/Example/Solution2/ExampleSolution2Page';

const ExampleRouter = [
  {
    path: '/example',
    element: <ExamplePage />,
  },
  {
    path: '/example/solution1',
    element: < ExampleSolution1Page />,
  },
  {
    path: '/example/solution2',
    element: <ExampleSolution2Page />,
  },
];

export default ExampleRouter;
