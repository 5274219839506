import { AspectRatio, Box, Button, Center, Container, HStack, Image, Spacer, Spinner, Text } from '@chakra-ui/react'
import React, { useCallback, useEffect, useRef, useState } from 'react'
import { MdGroupWork } from 'react-icons/md'
import * as emoji from 'node-emoji'
import { EDITOR_JS_TOOLS } from './editorData'
import { createReactEditorJS } from 'react-editor-js'
import { FaIcons, FaRegImage } from 'react-icons/fa'
import { FiShare } from 'react-icons/fi'
import { useParams } from 'react-router-dom'
import { getSingleDocumentFirebase, setDocumentFirebase } from '../../../Api/firebaseApi'
import moment from 'moment'


function EditorComponent() {
  const [isLoading,setIsLoading]=useState(false)
  const [data,setData]=useState()
  const ReactEditorJS = createReactEditorJS()
  const editorCore = useRef(null)
  const params = useParams()
  const placeholder='Add your notes. Use "/" for commands.'

  const handleInitialize = useCallback((instance) => {
    editorCore.current = instance
  }, [])

  const handleSave = useCallback(async () => {
    try {
      setIsLoading(true)
      const savedData = await editorCore.current.save();
      const res = await setDocumentFirebase('notes',params.fileId,savedData)
      console.log(res,'ini hasil save data')
        
    } catch (error) {
      console.log('Save failed: ', error.message)
        
    }
    setIsLoading(false)
  }, [])


  const getData = async() => {
    setIsLoading(true)
    try {
      const res = await getSingleDocumentFirebase('notes',params.fileId)
      if(res?.blocks?.length>0)
        setData(res)
      else
        setData({
          time: moment().unix(),
          blocks: [
            {
              type: 'header',
              data: {
                text: placeholder,
                level: 1
              }
            }
          ],
          version: '2.22.0'
        })

    } catch (error) {
      console.log(error.message)
    }
    setIsLoading(false)

  }


  useEffect(() => {
    console.log(params.fileId)
    getData()
    return () => setData()
  }, [params?.fileId])

  return (
    <Box w='full' >
      <HStack w='full' pos='sticky' top='0' p='2'>
        <Spacer/>
        {/* <Text>Add Cover</Text> */}
        <FaRegImage/>
        <FaIcons/>
        <FiShare/>
        {/* <Text>Add Icon</Text> */}
        {/* <Text>Kodok</Text>
          <Text>Kodok</Text>
          <Text>Kodok</Text> */}
        <Button size='sm' colorScheme='green' onClick={() => handleSave()}>Save</Button>
      </HStack>
        
      <AspectRatio w='full' ratio={5 /1}>
        <Image src='https://www.notion.so/images/page-cover/nasa_robert_stewart_spacewalk_2.jpg' alt='naruto' objectFit='cover' />
      </AspectRatio>
      {/* </AspectRatio> */}
      <Container  mt='-75' pos='relative'>
        <Text fontSize='75px'>{emoji.random()?.emoji }</Text>
        {/* <Icon as={MdGroupWork} w={20} h={20} /> */}
      </Container>
      {isLoading?<Center><Spinner/></Center>:<></>}
      <Container>
        <ReactEditorJS
          //   defaultBlock='title' 
          onInitialize={handleInitialize}
          //   readOnly={true}
          //   defaultValue={data}
          value={data}
          autofocus={true}
          //   onChange={(e) => console.log(e)}
          placeholder={placeholder}
          tools={EDITOR_JS_TOOLS}
        />
      </Container>
    </Box>
  )
}

export default EditorComponent
