import { Box, Button, Flex, Heading, HStack, Input, SimpleGrid, Spacer, Text } from '@chakra-ui/react'
import { Link, useParams } from 'react-router-dom'
import React from 'react'
import BackButtons from '../../Components/Buttons/BackButtons'
import FolderLayout from '../../Layouts/FolderLayout'
import FileLayout from '../../Layouts/FileLayout'
import ContentLayout from '../../Layouts/ContentLayout'
import MindMapAutoLayout from './MindMapTemplate/AutoLayout/MindMapAutoLayout'
import MindMapViewLayout from './MindMapTemplate/AutoLayout/MindMapViewLayout'


export const MindMapPage = () => {
  const params = useParams()

  return(
    <Flex height="100vh">
     
      <FolderLayout pageName='mindmap' />
      <FileLayout pageName='mindmap' />
      <ContentLayout >
        {params?.fileId?
          <MindMapViewLayout/>
          :
          <Text>Choose a folder & a file</Text>}
      </ContentLayout>
   

    </Flex>
    // <Box>
    //   <HStack>
    //     <BackButtons/>
    //     <Heading>Mind Map</Heading>
    //     <Spacer/>
    //     <Input type='text' w='2xs' placeholder='Search'/>
    //     <Button colorScheme='green'>Add</Button>
    //   </HStack>

  //   <SimpleGrid gap='2' columns={{base:1 ,md:4}} mt='2'>

  //     {data.map((x,i) =>
  //       <Link to={`/mindmap/${x.id}`} key={i}>
  //         <Box  borderRadius='lg' p='2' border='1px'>
  //           <Heading fontSize='lg'>{x.title}</Heading>
  //         </Box>
  //       </Link>
  //     )}

  //   </SimpleGrid>
  // </Box>
  )
}



