import { Box, Heading, HStack, SimpleGrid, Spacer } from '@chakra-ui/react'
import React from 'react'
import { FcMindMap } from 'react-icons/fc'
import { MdOutlineFlashOn } from 'react-icons/md'
import BackButtons from '../../../Components/Buttons/BackButtons'
import DynamicButton from '../../../Components/Buttons/DynamicButton'
import CardMyKanban from '../../../Components/Card/CardMyKanban'
import { useNavigate } from 'react-router-dom'

function ExampleSolution1Page() {
  const data = [
    { id: 'asd123', title: 'card 1', icon: FcMindMap, user: ['id1', 'id2', 'id3'] }
  ]
  return (
    <Box>
      <HStack>
        <BackButtons />
        <Heading>Solution #1</Heading>
        <Spacer />
        <DynamicButton
          title={'Automations'} action="custom"
          icon={MdOutlineFlashOn}
          color="yellow"
          variant={'solid'}
          onClick={() => console.log('do something here')}
        />
      </HStack>

      <SimpleGrid columns='4'>
        {data?.map((x, i) =>
          <CardMyKanban key={i} Icon={x.icon} onNavigate={`/example/solution1/${x.id}`} title={x.title} userArray={x.userArray} />
        )}

      </SimpleGrid>
    </Box>
  )
}

export default ExampleSolution1Page
