import { PlusSquareIcon } from '@chakra-ui/icons'
import { Accordion, AccordionButton, AccordionIcon, AccordionItem, AccordionPanel, Box, Button, HStack, Input, Modal, ModalBody, ModalCloseButton, ModalContent, ModalFooter, ModalHeader, ModalOverlay, Spacer, Text, useDisclosure } from '@chakra-ui/react'
import React, { useEffect, useState } from 'react'
import { FcFolder, FcOpenedFolder } from 'react-icons/fc'
import { Link, useNavigate, useParams } from 'react-router-dom'
import PropTypes from 'prop-types';
import { clientTypessense } from '../Api/Typesense'
import useUserStore from '../Hooks/Zustand/Store'
import { addDocumentFirebase } from '../Api/firebaseApi'

FolderLayout.propTypes = {
  pageName: PropTypes.string.isRequired,
};

function FolderLayout(props) {
  const [selectFolder, setSelectFolder] = useState('*') 
  const { isOpen, onOpen, onClose } = useDisclosure()
  const [newFolder, setNewFolder] = useState('')
  const [isLoading,setIsLoading]=useState(false)
  const [folderName, setFolderName] = useState([])
  const page = props.pageName;
  const params = useParams()    

  const navigate = useNavigate()
  const globalState=useUserStore()

  const hadndleSave = async() => {    
    try {
      setIsLoading(true)
      const data = {title:newFolder,owner:[globalState.uid],type:page}
      //   console.log(data)
      const res = await addDocumentFirebase('folders',data,globalState.currentCompany)
      console.log(data,res,'ini res')
      navigate(`/${page}/${res}`)
    } catch (error) {
      console.log(error.message)
    }
    setIsLoading(false)
    onClose()
  }




  const getData = (q) => {
    const searchParameters = {
      q: q,
      query_by: 'title',
      filter_by: `owner: ${globalState.uid} && type: ${page}`,
    }; 

    clientTypessense
      .collections('folders')
      .documents()
      .search(searchParameters)
      .then((x) => {
        const newData = x.hits.map((y) => { return { ...y.document } })
        setFolderName(newData)
      });  
  }

  useEffect(() => {
    getData('*')
  }, [selectFolder])
  return (
    <Box
      height="full"
      width={{
        md: '10rem',
        xl: '12rem',
      }}
      display={{
        base: 'none',
        lg: 'initial',
      }}
      overflowY="auto"
      borderRightWidth="1px"
    >
      {/* <Navbar /> */}
      <HStack p='2'>
        <Text>Folder</Text>
        <Spacer/>
        <PlusSquareIcon cursor='pointer'  onClick={onOpen}/>
        {/* <Button size='2xs' fontSize='2xs' colorScheme='green' p='1' borderRadius='full'>+</Button> */}
      </HStack>

      <Box mx='2'>
        <Input type='text' onChange={(e) => getData(e.target.value)} placeholder='Search Folder' borderRadius='md' size='xs'/>
      </Box>

      <HStack spacing="3" cursor='pointer' px='1' onClick={() => navigate(`/${page}/`)}>
        {params?.folderId?<FcFolder/>:<FcOpenedFolder/>}
        <Text fontSize='sm' > All Files</Text>
        {/* <Spacer/>
        <Text fontSize='sm' >12</Text> */}
      </HStack>


      <Accordion defaultIndex={[1]}>
        <AccordionItem>
          <h2>
            <AccordionButton>
              <Box as="span" flex='1' textAlign='left' fontSize='xx-small'>
              Shared
              </Box>
              <AccordionIcon />
            </AccordionButton>
          </h2>
          <AccordionPanel pb={4}>
            {folderName.map((item, index) => {  
              return (
                <HStack key={index} spacing="3" px='1' cursor='pointer' onClick={() => setSelectFolder(item.id)}>
                  {params.folderId===item.id?<FcOpenedFolder/>:<FcFolder/>}
                  <Text fontSize='sm' as={params?.folderId===item.id?'b':''}>{item.title}</Text>
                  {/* <Spacer/>
                  <Text fontSize='sm' >12</Text> */}
                </HStack>
              )}
            )}
          </AccordionPanel>
        </AccordionItem>

        <AccordionItem>
          <h2>
            <AccordionButton>
              <Box as="span" flex='1' textAlign='left' fontSize='xx-small'>
              My Folder
              </Box>
              <AccordionIcon />
            </AccordionButton>
          </h2>
          <AccordionPanel pb={4}>
            {folderName.map((item, index) => {  
              return (
                <HStack key={index} spacing="3" px='1' cursor='pointer' onClick={() => navigate(`/${page}/${item.id}`)}>
                  {params.folderId===item.id?<FcOpenedFolder/>:<FcFolder/>}
                  <Link to={`/${page}/${item.id}`}>
                    <Text fontSize='sm' as={params?.folderId===item.id?'b':''}>{item.title}</Text>
                  </Link>
                  {/* <Spacer/>
                  <Text fontSize='sm' >12</Text> */}
                </HStack>
              )}
            )}
          </AccordionPanel>
        </AccordionItem>
      </Accordion>


      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Folder name</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Input type='text' placeholder='Folder name' onChange={(e) => setNewFolder(e.target.value)}/>
            {/* <Lorem count={2} /> */}
            <Button isLoading={isLoading} onClick={() => hadndleSave()}>Save</Button>
          </ModalBody>

          {/* <ModalFooter>
            <Button colorScheme='blue' mr={3} onClick={onClose}>
              Close
            </Button>
            <Button variant='ghost'>Secondary Action</Button>
          </ModalFooter> */}
        </ModalContent>
      </Modal>
    </Box>
  )
}

export default FolderLayout
