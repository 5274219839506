import React from 'react'

function CompanyPage() {
  return (
    <div>
            This is company page
    </div>
  )
}

export default CompanyPage
