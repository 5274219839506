import { PlusSquareIcon } from '@chakra-ui/icons'
import { Accordion, AccordionButton, AccordionIcon, AccordionItem, AccordionPanel, Box, Button, Center, Drawer, DrawerContent, DrawerOverlay, HStack, Icon, Input, Modal, ModalBody, ModalCloseButton, ModalContent, ModalHeader, ModalOverlay, SimpleGrid, Spacer, Text, useDisclosure } from '@chakra-ui/react'
import React, { useEffect, useState } from 'react'
import { FcFile } from 'react-icons/fc'
import { useNavigate, useParams } from 'react-router-dom'
import useUserStore from '../Hooks/Zustand/Store'
import PropTypes from 'prop-types';
import { clientTypessense } from '../Api/Typesense'
import { addDocumentFirebase } from '../Api/firebaseApi'

FileLayout.propTypes = {
  pageName: PropTypes.string.isRequired,
};
  
function FileLayout(props) {
  const [sidebarIsScrolled, setSidebarIsScrolled] = useState(false)
  const [fileName, setFileName] = useState()
  const [newFile,setNewFile]=useState()
  const { isOpen, onOpen, onClose } = useDisclosure()
  const [isLoading,setIsLoading]=useState(false)

  const page = props?.pageName.toLowerCase();
  const params = useParams()    

  const navigate = useNavigate()
  const globalState=useUserStore()

  const hadndleSave = async() => {    
    try {
      setIsLoading(true)
      const data = {
        title:newFile,
        type:page,
        folderId:params.folderId}
      const res = await addDocumentFirebase('files',data,globalState.currentCompany)
      
      setTimeout(() => {
        getData('*')
        navigate(`/${page}/${params.folderId}/${res}`)
      }, 1000);

    } catch (error) {
      console.log(error.message)
    }
    setIsLoading(false)
    onClose()
  }

  const getData = (q) => {
    let filter = `createdBy: ${globalState.uid} && type: ${page} `
    if(params?.folderId)
      filter+= `&& folderId: ${params.folderId}`
    const searchParameters = {
      q: q,
      query_by: 'title',
      filter_by: filter,
    }; 

    clientTypessense
      .collections('files')
      .documents()
      .search(searchParameters)
      .then((x) => {
        const newData = x.hits.map((y) => { return { ...y.document } })
        setFileName(newData)
      });  
  }

  useEffect(() => {
    getData('*')
  }, [page,params?.folderId,params?.fileId])
  return (
    <Box
      borderRightWidth="1px"
      width={{
        md: '18rem',
        xl: '20rem',
      }}
      display={{
        base: 'none',
        md: 'initial',
      }}
      overflowY="auto"
      onScroll={(x) => setSidebarIsScrolled(x.currentTarget.scrollTop > 32)}
    >
      {/* <ColumnHeader shadow={sidebarIsScrolled ? 'base' : 'none'}> */}
      <HStack justify="space-between" width="full">
        <HStack spacing="3" p='2' w='full'>
          {/* <ColumnIconButton
            onClick={onOpen}
            aria-label="Open Navigation"
            icon={<FiMenu />}
            display={{
              md: 'inline-flex',
              lg: 'none',
            }}
          /> */}
          {/* <Drawer isOpen={isOpen} placement="left" onClose={onClose}> */}
          {/* <DrawerOverlay /> */}
          {/* <DrawerContent> */}
          {/* <Navbar onClose={onClose} /> */}
          {/* <Text>Files</Text> */}

          {/* </DrawerContent> */}
          {/* </Drawer> */}

          {/* <ColumnHeading>Writing</ColumnHeading> */}
          <Text>Files</Text>
          <Spacer/>
          <PlusSquareIcon onClick={onOpen}/>
          {/* <Button size='2xs' fontSize='2xs' colorScheme='green' p='1' borderRadius='full'>+</Button> */}
        </HStack>
        {/* <ColumnButton leftIcon={<FiRss />}>Subscribe</ColumnButton> */}
      </HStack>
      {/* </ColumnHeader> */}
      {/* <Sidebar /> */}
      <Box mx='2'>
        <Input type='text' onChange={(e) => getData(e.target.value)} placeholder='Search Files' borderRadius='md' size='xs'/>
      </Box>

      <SimpleGrid columns={3}>
        { fileName?.map((item, index) => {  
          return (
            <Box key={index} onClick={() => navigate(`/${page}/${params.folderId}/${item.id}`)} spacing="3" cursor='pointer' border={item.id===params.fileId?'1px':'0px'} borderRadius='md' p='2' m='2' >
              <Center>
                <Icon as={FcFile} boxSize={10} />
              </Center>
              <Text textAlign='center' fontSize='small'>{item.title}</Text>
              {/* <Text>description goes here all the way from alabama</Text> */}
            </Box>
          )}
        )}
      </SimpleGrid>

      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Notes Title</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Input type='text' placeholder='Notes title' onChange={(e) => setNewFile(e.target.value)}/>
            {/* <Lorem count={2} /> */}
            <Button isLoading={isLoading} onClick={() => hadndleSave()}>Save</Button>
          </ModalBody>

          {/* <ModalFooter>
            <Button colorScheme='blue' mr={3} onClick={onClose}>
              Close
            </Button>
            <Button variant='ghost'>Secondary Action</Button>
          </ModalFooter> */}
        </ModalContent>
      </Modal>
    </Box>
  )
}

export default FileLayout
