import {

  FcBriefcase,
  FcCollaboration,
  FcMindMap,
  FcWorkflow,
  FcPuzzle,
  FcMoneyTransfer,
  FcManager,
  FcOrganization,
  FcTodoList,
  FcDisplay,
  FcPositiveDynamic,
  FcElectricalSensor,
  FcViewDetails,
  FcReading,
  FcTemplate,
  FcKey,
  FcFlashOn,
  FcReadingEbook,
  FcFeedback,
  FcBookmark,
  FcCalendar
} from 'react-icons/fc';

export const dataMenu = [

  // {
  //   name: 'Example',
  //   icon: FcPuzzle,
  //   link: '/example',
  //   description:
  //     'Elevate business efficiency, simplifying task management, collaboration, and scheduling to drive informed decisions and boost productivity.',
  //   submenu: [
  //     {
  //       name: 'solution1',
  //       icon: FcMindMap,
  //       link: '/example/solution1',
  //       description: 'Elevate business efficiency, simplifying task management, collaboration, and scheduling to drive informed decisions and boost productivity.',
  //       submenu: [
  //         {
  //           name: 'subMenuSolution1',
  //           icon: FcWorkflow,
  //           link: '/example/solution1',
  //           description:
  //             'Elevate business efficiency, simplifying task management, collaboration, and scheduling to drive informed decisions and boost productivity.',
  //         },
  //         {
  //           name: 'subMenuSolution1',
  //           icon: FcWorkflow,
  //           link: '/example/solution1',
  //           description:
  //             'Elevate business efficiency, simplifying task management, collaboration, and scheduling to drive informed decisions and boost productivity.',
  //         },

  //       ]
  //     },
  //     {
  //       name: 'Solution2',
  //       icon: FcWorkflow,
  //       link: '/example/solution2',
  //       description:
  //         'Elevate business efficiency, simplifying task management, collaboration, and scheduling to drive informed decisions and boost productivity.',
  //     },
  //   ],
  // },

 

  {
    name: 'Email',
    icon: FcFeedback,
    link: '/email',
    description:
      'Elevate business efficiency, simplifying task management, collaboration, and scheduling to drive informed decisions and boost productivity.',
  },
  {
    name: 'Mindmap',
    icon: FcMindMap,
    link: '/mindmap',
    description:
      'Elevate business efficiency, simplifying task management, collaboration, and scheduling to drive informed decisions and boost productivity.',
  },
  {
    name: 'Bookmark',
    icon: FcBookmark,
    link: '/mindmap',
    description:
      'Elevate business efficiency, simplifying task management, collaboration, and scheduling to drive informed decisions and boost productivity.',
  },
  {
    name: 'Notes',
    icon: FcReadingEbook,
    link: '/notes',
    description:
      'Elevate business efficiency, simplifying task management, collaboration, and scheduling to drive informed decisions and boost productivity.',
  },
  {
    name: 'Calendar',
    icon: FcCalendar,
    link: '/mindmap',
    description:
      'Elevate business efficiency, simplifying task management, collaboration, and scheduling to drive informed decisions and boost productivity.',
  },

  {
    name: 'Configuration',
    icon: FcCollaboration,
    link: '/configuration',
    description:
      'All-in-one HRIS solution that simplifies HR tasks, enhances compliance, and provides valuable data-driven insights for strategic decision-making.',
    submenu: [

      {
        name: 'Company',
        icon: FcOrganization,
        link: '/configuration/company',
      },
      {
        name: 'Projects',
        icon: FcBriefcase,
        link: '/configuration/projects',
      },

      {
        name: 'Users',
        icon: FcManager,
        link: '/configuration/users',
        description:
          'Elevate business efficiency, simplifying task management, collaboration, and scheduling to drive informed decisions and boost productivity.',
      },
      {
        name: 'Billing',
        icon: FcMoneyTransfer,
        link: '/configuration/billing',
        description:
          'Elevate business efficiency, simplifying task management, collaboration, and scheduling to drive informed decisions and boost productivity.',
      },
    ],
  },
];
