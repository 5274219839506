/* eslint-disable no-dupe-keys */
// tools.js
import Embed from '@editorjs/embed'
import Table from '@editorjs/table'
import List from '@editorjs/list'
import Warning from '@editorjs/warning'
import Code from '@editorjs/code'
import LinkTool from '@editorjs/link'
import Image from '@editorjs/image'
import Raw from '@editorjs/raw'
import Header from '@editorjs/header'
import Quote from '@editorjs/quote'
import Marker from '@editorjs/marker'
import CheckList from '@editorjs/checklist'
import Delimiter from '@editorjs/delimiter'
import InlineCode from '@editorjs/inline-code'
import SimpleImage from '@editorjs/simple-image'

import ImageGallery from '@rodrigoodhin/editorjs-image-gallery'
// import  Undo  from 'editorjs-undo'
// import  AttachesTool  from '@editorjs/attaches'
import AnyButton  from 'editorjs-button'
import ToggleBlock from 'editorjs-toggle-block';
import Alert from 'editorjs-alert';
import editorjsNestedChecklist from '@calumk/editorjs-nested-checklist';
import Title from 'title-editorjs';
import NestedList from '@editorjs/nested-list';


export const EDITOR_JS_TOOLS = {
  // NOTE: Paragraph is default tool. Declare only when you want to change paragraph option.
  // paragraph: Paragraph,
  title: Title,
  embed: Embed,
  table: Table,
  list: List,
  warning: Warning,
  code: Code,
  linkTool: LinkTool,
  image: Image,
  raw: Raw,
  header: Header,
  quote: Quote,
  marker: Marker,
  delimiter: Delimiter,
  inlineCode: InlineCode,
  simpleImage: SimpleImage,
  checklist: CheckList,
  nestedchecklist : editorjsNestedChecklist,
  alert:Alert,
  imageGallery: ImageGallery,
  AnyButton: {
    class: AnyButton,
    inlineToolbar: false,
    config:{
      css:{
        'btnColor': 'btn--gray',
      }
    }
  },
  toggle: {
    class: ToggleBlock,
    inlineToolbar: true,
  },
  list: {
    class: NestedList,
    inlineToolbar: true,
    config: {
      defaultStyle: 'unordered'
    },
  }

}