import React, { useEffect } from 'react';
import { Route, Routes, useLocation, useNavigate } from 'react-router-dom';
import useUserStore from '../Hooks/Zustand/Store';

import Layout from '../Layouts';
import HomeRouter from './HomeRouter';
import SettingRouter from './SettingRouter';
import AuthenticationMainRouter from './AuthenticationMainRouter';
import ExampleRouter from './ExampleRouter';
import ConfigurationRouter from './ConfigurationRouter';
import NotesRouter from './NotesRouter';
import MindMapRouter from './MindMapRouter';
import MindMapAutoLayout from '../Pages/Mindmap/MindMapTemplate/AutoLayout/MindMapAutoLayout';



function MainRouter() {

  const globalState = useUserStore();
  const location = useLocation()
  const navigate = useNavigate()

  const allRouter = [
    ...AuthenticationMainRouter,
    ...SettingRouter,
    ...ConfigurationRouter,
    ...ExampleRouter,
    ...HomeRouter,
    ...ConfigurationRouter,
    ...NotesRouter,
    ...MindMapRouter
  ];

  useEffect(() => {
    const path = location.pathname;

    if (globalState.roleCompany !== 'owner') {
      if (
        path === '/integration'
      ) {
        return navigate('/no-access');
      }
    }
    return () => {
    }
  }, [location.pathname]);


  return (
    <Routes>
      <Route path="mindmap/:folderId/:fileId/edit" element={<MindMapAutoLayout />} />

      {allRouter.map((item, index) => {
        return <Route key={index} path={item.path} element={<Layout>{item.element}</Layout>} />;
      })}
    </Routes>
  );
}

export default MainRouter;
