import { Box, Heading, HStack, Spacer } from '@chakra-ui/react'
import React from 'react'
import BackButtons from '../../../Components/Buttons/BackButtons'
import DynamicButton from '../../../Components/Buttons/DynamicButton'
import { MdOutlineFlashOn } from 'react-icons/md'

function ExampleSolution2Page() {
  return (
    <Box>
      <HStack>
        <BackButtons />
        <Heading>Solution #1</Heading>
        <Spacer />
        <DynamicButton
          title={'Automations'} action="custom"
          icon={MdOutlineFlashOn}
          color="yellow"
          variant={'solid'}
          onClick={() => console.log('do something here')}
        />
      </HStack>
    </Box>
  )
}

export default ExampleSolution2Page
