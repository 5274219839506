export const nodes = [
  {
    id: '1',
    data: { label: 'Mindmap kamu' },
    position: { x: 0, y: 0 },
    type: 'custom',


  },
  // {
  //   id: '2',
  //   data: { label: 'Node 2' },
  //   position: { x: 0, y: 0 },
  //   type: 'custom',

  // },
  // {
  //   id: '3',
  //   data: { label: 'Node 3' },
  //   position: { x: 0, y: 0 },
  //   type: 'custom',

  // },
  // {
  //   id: '4',
  //   data: { label: 'Node 4' },
  //   position: { x: 0, y: 0 },
  // },
  // {
  //   id: '5',
  //   data: { label: 'Node 5' },
  //   position: { x: 0, y: 0 },
  // },
];
  
export const edges = [
  {
    id: '1->2',
    source: '1',
    target: '2',
    // type: 'floating',

  },
  // {
  //   id: '1->3',
  //   source: '1',
  //   target: '3',
  //   // type: 'floating',
  // },
  // {
  //   id: '4->5',
  //   source: '4',
  //   target: '5',
  // },
];