import React from 'react';
import { MindMapPage } from '../Pages/Mindmap/MindMapPage';

const MindMapRouter = [
  {
    path: '/mindmap',
    element: <MindMapPage />,
  },
  {
    path: '/mindmap/:folderId',
    element: <MindMapPage />,
  },
  {
    path: '/mindmap/:folderId/:fileId',
    element: <MindMapPage />,
  },

];

export default MindMapRouter;
