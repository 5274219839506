import React from 'react';
import CompanyPage from '../Pages/Configurations/Companies/CompanyPage';
import ProjectsPage from '../Pages/Configurations/Projects/ProjectPage';
import ProjectsViewPage from '../Pages/Configurations/Projects/ProjectViewPage';
import UsersPage from '../Pages/Configurations/Users/UsersPage';
import ConfigurationPage from '../Pages/Configurations/ConfigurationPage';
import BillingsPage from '../Pages/Configurations/Billings/BillingsPage';


const ConfigurationRouter = [
  {
    path: '/configuration',
    element: <ConfigurationPage />,
  },
  {
    path: '/configuration/company',
    element: <CompanyPage />,
  },
  {
    path: '/configuration/projects',
    element: <ProjectsPage />,
  },
  {
    path: '/configuration/projects/:id',
    element: <ProjectsViewPage />,
  },
  {
    path: '/configuration/users',
    element: <UsersPage />,
  },
  {
    path: '/configuration/billing',
    element: <BillingsPage />,
  }
];

export default ConfigurationRouter;
