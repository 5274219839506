import { useState, useCallback, useEffect, useRef } from 'react';
import {
  Node,
  useKeyPress,
  useReactFlow,
  useStore,
} from 'reactflow';

export function useTabEnter() {
  const mousePosRef = useRef({ x: 0, y: 0 });
  const rfDomNode = useStore((state) => state.domNode);
  //   const [nodes, setNodes, onNodesChange] = useNodesState();
  //   const [edges, setEdges, onEdgesChange] = useEdgesState();

  const { getNodes, getEdges,setNodes, setEdges,screenToFlowPosition } =
    useReactFlow();

  // Set up the paste buffers to store the copied nodes and edges.
  const [bufferedNodes, setBufferedNodes] = useState([] );
  const [bufferedEdges, setBufferedEdges] = useState([] );

  // initialize the copy/paste hook
  // 1. remove native copy/paste/cut handlers
  // 2. add mouse move handler to keep track of the current mouse position
  useEffect(() => {
    const events = ['tab', 'enter'];

    if (rfDomNode) {
      const preventDefault = (e) => e.preventDefault();

      const onMouseMove = (event) => {
        mousePosRef.current = {
          x: event.clientX,
          y: event.clientY,
        };
      };

      for (const event of events) {
        rfDomNode.addEventListener(event, preventDefault);
      }

      rfDomNode.addEventListener('mousemove', onMouseMove);

      return () => {
        for (const event of events) {
          rfDomNode.removeEventListener(event, preventDefault);
        }

        rfDomNode.removeEventListener('mousemove', onMouseMove);
      };
    }
  }, [rfDomNode]);

  const addNode = useCallback(
    (parentNode) => {
      // return console.log(parentNode,'ini di addnode')
      const childNodeId = Date.now().toString();
      const childNode = {
        id: childNodeId,
        data: { label: `Node ${getNodes()?.length + 1}` },
        position: { x: parentNode.position.x+200, y: parentNode.position.y },
        style: { opacity: 1 },
        sourcePosition:parentNode.sourcePosition,
        targetPosition:parentNode.targetPosition,
        height:40,
        width:150,
        type: 'custom',


      };
      const connectingEdge = {
        id: `${parentNode.id}->${childNodeId}`,
        source: parentNode.id,
        target: childNodeId,
        style: { opacity: 1 },
      };

      setNodes((nodes) => nodes.concat([childNode]));
      setEdges((edges) => edges.concat([connectingEdge]));

      return {childNode,connectingEdge}
    },
    [setNodes, setEdges,]
  );

  const tab = useCallback(() => {
    const selectedNodes = getNodes().filter((node) => node.selected);

    const newNode= addNode(selectedNodes[0])
    console.log(newNode)
    // setBufferedNodes(selectedNodes);
    // setBufferedEdges(selectedEdges);
  }, [getNodes, getEdges]);

  const enter = useCallback(() => {
    const selectedNodes = getNodes().filter((node) => node.selected);

    const selectedEdges = getEdges().find((x) => x.target===selectedNodes[0].id)
    if(!selectedEdges?.source) return

    const addNewNode = getNodes().filter((node) => node.id ===selectedEdges.source);

    // return console.log(addNewNode,'ini node yang akan di tambahin')
    const newNode= addNode(addNewNode[0])
    console.log(newNode)

  }, [getNodes, setNodes, getEdges, setEdges]);


  useShortcut(['Tab'], tab);
  useShortcut(['Enter'], enter);

  return { tab, enter, bufferedNodes, bufferedEdges };
}

function useShortcut(keyCode, callback) {
  const [didRun, setDidRun] = useState(false);
  const shouldRun = useKeyPress(keyCode);

  useEffect(() => {
    if (shouldRun && !didRun) {
      callback();
      setDidRun(true);
    } else {
      setDidRun(shouldRun);
    }
  }, [shouldRun, didRun, callback]);
}

export default useTabEnter;