import React from 'react';
import NotesPage from '../Pages/Notes/NotesPage';

const NotesRouter = [
  {
    path: '/notes',
    element: <NotesPage />,
  },
  {
    path: '/notes/:folderId',
    element: <NotesPage />,
  },
  {
    path: '/notes/:folderId/:fileId',
    element: <NotesPage />,
  },

  //   {
  //     path: '/chat-user/:module/:companyId/:projectId',
  //     element: <ChatPageFirst />,
  //   },




];

export default NotesRouter;
