/* eslint-disable react/prop-types */
import React from 'react';
import {
  Stack,
  VStack,
  HStack,
  Text,
  Spacer,
  AvatarGroup,
  Avatar,
  useColorMode,
} from '@chakra-ui/react';

function CardMyKanban({
  Icon,
  onNavigate,
  title,
  userArray
}) {

  const { colorMode } = useColorMode();


  return (
    <Stack shadow="base" >
      <VStack align="flex-start" w="full" h="full" justify="space-between"
        bgColor={colorMode === 'dark' ? 'gray.700' : 'white'}

      >
        <HStack spacing={3} px={4} pt={4} w="100%">
          <Stack cursor="pointer" onClick={onNavigate}>
            {Icon && <Icon size={50} />}
          </Stack>
          <Stack>
            <Text
              textTransform="capitalize"
              cursor="pointer"
              noOfLines={1}
              fontSize="sm"
              fontWeight="medium"
              onClick={onNavigate}
            >{title}
            </Text>
           
          </Stack>
        </HStack>

        <HStack
          alignItems="center"
          justifyContent="space-around"
          w="full"
          p={3}
          mt={3}
        >
          <Stack spacing={0}>
            <Text spacing={3} fontSize="2xs">
              Project: All Project
            </Text>
           
          </Stack>
          <Spacer />
          {userArray?.length > 0 && (
            <AvatarGroup
              cursor="pointer"
              size="sm"
              max={5}
            >
              {userArray?.map((user, i) => (
                <Avatar key={i} name={user.name} />
              ))}
            </AvatarGroup>
          )}
        </HStack>
      </VStack>
    </Stack>
  )
}

export default CardMyKanban