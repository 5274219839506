import React, { useEffect } from 'react'
import ReactFlow, { Controls, MarkerType, Panel, ReactFlowProvider, useEdgesState, useNodesState, useReactFlow } from 'reactflow'
import { useControls, Leva } from 'leva';
import CustomNode from './Component/CustomNode';
import { nodes as initialNodes, edges as initialEdges } from './Component/InitialElements';
import { MindMapTopLeft } from '../Panels/MindMapeTopeLeft';
import { MindMapBottomCenter } from '../Panels/MindMapBottomCenter';
import { MindMapTopRight } from '../Panels/MindMapTopRight';
import { Box } from '@chakra-ui/react';
import { FcFinePrint } from 'react-icons/fc';
import { useNavigate } from 'react-router-dom';

const nodeTypes = {
  custom: CustomNode,
  // selectorNode: ColorSelectorNode,
  // 'node-with-toolbar': NodeWithToolbar,
};
  
function MindMapViewLayout() {
  const navigate = useNavigate()

  function ReactFlowAutoLayout() {
    const { fitView, addNodes } = useReactFlow();
    const [nodes, setNodes, onNodesChange] = useNodesState(initialNodes);
    const [edges, setEdges, onEdgesChange] = useEdgesState(initialEdges);
  
      
    const proOptions = {
      account: 'paid-pro',
      hideAttribution: true,
    };
        
    const defaultEdgeOptions = {
      type: 'beizer',
      markerEnd: { type: MarkerType.ArrowClosed },
      pathOptions: { offset: 5 },
    };
        
      
    // const edgeTypes = {
    //   floating: FloatingEdge,
    // };
      
      
        
    // const layoutOptions = useControls(position);
      
    // useAutoLayout(layoutOptions);
      
    
    useEffect(() => {
      fitView();
    }, [nodes?.length]);
      
    return (
      <ReactFlow
        nodes={nodes}
        edges={edges}
        // onNodesChange={onNodesChange}
        // onEdgesChange={onEdgesChange}
        // onConnect={onConnect}
        // onNodeClick={onNodeClick}
        nodesDraggable={false}
        // nodesFocusable={(e)=>console.log(e,'ini nodes focusable')}
        // defaultEdgeOptions={defaultEdgeOptions}
        // connectionLineType={ConnectionLineType.SmoothStep}
        proOptions={proOptions}
        zoomOnDoubleClick={false}
        // edgeTypes={edgeTypes}
        nodeTypes={nodeTypes}
        // onNodeDragStart={onNodeDragStart}
        // onSelectionDragStart={onSelectionDragStart}
        // onNodesDelete={onNodesDelete}
        // onEdgesDelete={onEdgesDelete}
        // onNodeMouseEnter={(e)=>console.log(e)}
        fitView
      >
        {/* <Panel position="top-left"><MindMapTopLeft/></Panel> */}
        {/* <Panel position="bottom-center"><MindMapBottomCenter/></Panel> */}
        <Panel position="top-right">
          <Box bgColor='white' p='2' borderRadius='md'>
            <FcFinePrint onClick={() => navigate('edit')}/>
          </Box>
          {/* <MindMapTopRight 
            // undo={undo} redo={redo}
          /> */}
        </Panel>
        <Controls/>
      
      </ReactFlow>
    );
  }
  return (
    <ReactFlowProvider>
      <Leva hidden={true} />
      <Box width='full' height='full'>
        <ReactFlowAutoLayout />
      </Box>
    </ReactFlowProvider>
  )
}

export default MindMapViewLayout
