import { Box, HStack, Heading, Spacer, Text, useColorModeValue } from '@chakra-ui/react'
import React, { useState } from 'react'

import PropTypes from 'prop-types';
import { FaIcons, FaRegImage } from 'react-icons/fa';
ContentLayout.propTypes = {
  children: PropTypes.node.isRequired,
}

function ContentLayout({ children }) {
  const [mainIsScrolled, setmMainIsScrolled] = useState(false)

  return (
    <Box
      bg={useColorModeValue('white', 'gray.900')}
      flex="1"
      overflowY="auto"
      onScroll={(x) => setmMainIsScrolled(x.currentTarget.scrollTop > 32)}
    >
     
      {/* <ColumnHeader shadow={mainIsScrolled ? 'base' : 'none'}> */}
      <HStack justify="space-between" width="full">
        <HStack spacing="3">
          {/* <ColumnIconButton
          aria-label="Navigate back"
          icon={<FiArrowLeft />}
          display={{
            base: 'inline-flex',
            md: 'none',
          }}
        /> */}
          {/* {mainIsScrolled && <ColumnHeading>The shape of a cupcake</ColumnHeading>} */}
        </HStack>
        {/* <ColumnButton leftIcon={<FiHeart />}>12</ColumnButton> */}
      </HStack>
      {/* <Heading>Content here</Heading> */}
      {/* <Box id='editorjs' border='1px' mt='10'/> */}

      <Box  h='full'>
       
      
        {children}
      </Box>

      {/* {editor} */}
      {/* </ColumnHeader> */}
      {/* <Main
      maxW="3xl"
      mx="auto"
      py="8"
      px={{
        base: '4',
        md: '8',
      }}
    /> */}
    </Box>
  )
}

export default ContentLayout
