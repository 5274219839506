import React from 'react'
import MainMenuComponent from '../../Components/Menus/MainMenuComponent'

function ExamplePage() {

  return (
    <MainMenuComponent
      menuName='Example'
    />
  )
}

export default ExamplePage
