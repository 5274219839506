import { AspectRatio, Button, Container, Heading, Icon, Image, Input, Spacer, Stack, Text, useDisclosure } from '@chakra-ui/react'
import React, { useEffect, useRef, useState } from 'react'
import {
  Box,
  Drawer,
  DrawerContent,
  DrawerOverlay,
  Flex,
  HStack,
  useColorModeValue,
} from '@chakra-ui/react'
import { FiArrowLeft, FiHeart, FiMenu, FiRss, FiShare, FiShare2 } from 'react-icons/fi'
import { FcFile, FcFolder, FcOpenedFolder } from 'react-icons/fc'

import FolderLayout from '../../Layouts/FolderLayout'
import FileLayout from '../../Layouts/FileLayout'
import ContentLayout from '../../Layouts/ContentLayout'
import { FaIcons, FaRegImage } from 'react-icons/fa'
import { useParams } from 'react-router-dom'
import EditorComponent from './Component/EditorComponent'



function NotesPage() {
  const [data,setData]=useState({page:'notes'})
  const params=useParams()

  useEffect(() => {
  }, [data])

  return (
    <Flex height="100vh">
     
      <FolderLayout pageName='notes' />
      <FileLayout pageName='notes' />
      <ContentLayout >
        {params?.fileId?
          <EditorComponent/>
          :
          <Text>Choose a folder & a file</Text>}
      </ContentLayout>
     

    </Flex>
    
  )
}

export default NotesPage
