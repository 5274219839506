import React, { useEffect, useCallback, useState } from 'react';
import ReactFlow, {
  MarkerType,
  ReactFlowProvider,
  useReactFlow,
  NodeMouseHandler,
  useNodesState,
  useEdgesState,
  OnConnect,
  addEdge,
  ConnectionLineType,
  Panel,
  Controls,
  NodeToolbar,
  getIncomers,
  getOutgoers,
  getConnectedEdges,
} from 'reactflow';
import { useControls, Leva } from 'leva';
// import useAutoLayout, { type LayoutOptions } from './useAutoLayout';
import { nodes as initialNodes, edges as initialEdges } from './Component/InitialElements';
import 'reactflow/dist/style.css';
import useAutoLayout from './Component/useAutoLayout';
import { MindMapTopLeft } from '../Panels/MindMapeTopeLeft';
import FloatingEdge from '../FloatingEdge/components/FloatingEdge';
import { MindMapBottomCenter } from '../Panels/MindMapBottomCenter';
import useUndoRedo from '../UndoRedo/Component/useUndoRedo';
import { MindMapTopRight } from '../Panels/MindMapTopRight';
import useCopyPaste from '../Hooks/useCopyPaste';
import useTabEnter from '../Hooks/useTabEnter';
import CustomNode from './Component/CustomNode';
import useExpandCollapse from '../Hooks/useExpandCollapse';

const nodeTypes = {
  custom: CustomNode,
  // selectorNode: ColorSelectorNode,
  // 'node-with-toolbar': NodeWithToolbar,
};


function ReactFlowAutoLayout() {
  const { fitView, addNodes } = useReactFlow();
  const [nodes, setNodes, onNodesChange] = useNodesState(initialNodes);
  const [edges, setEdges, onEdgesChange] = useEdgesState(initialEdges);
  const { undo, redo, takeSnapshot } = useUndoRedo();
  
  const treeWidth = 220
  const treeHeight = 100
  const animationDuration = 300

  useCopyPaste();
  useTabEnter();

  useExpandCollapse(
    nodes,
    edges,
    { treeWidth, treeHeight }
  );

  const options = {
    about: {
      value:
        'Add child nodes by clicking a node in the graph. Add new root nodes by clicking the button below.',
      editable: false,
    },
    algorithm: {
      value: 'd3-hierarchy',
      options: [ 'd3-hierarchy'],
    },
    direction: {
      value: 'LR',
      options: {
        down: 'TB',
        right: 'LR',
        up: 'BT',
        left: 'RL',
      },
    },
    spacing: [50, 5],
    // 'add root node': button(() =>
    //   addNodes({
    //     id: getId(),
    //     position: { x: 0, y: 0 },
    //     data: { label: `New Node` },
    //     style: { opacity: 0 },
    //   })
    // ),
  }
  const [position,setPosition]=useState(options)

  const proOptions = {
    account: 'paid-pro',
    hideAttribution: true,
  };
  
  const defaultEdgeOptions = {
    type: 'beizer',
    markerEnd: { type: MarkerType.ArrowClosed },
    pathOptions: { offset: 5 },
  };
  

  const edgeTypes = {
    floating: FloatingEdge,
  };


  
  const layoutOptions = useControls(position);

  useAutoLayout(layoutOptions);

  const onNodeDragStart= useCallback(() => {
    // 👇 make dragging a node undoable
    takeSnapshot();
    // 👉 you can place your event handlers here
  }, [takeSnapshot]);

  const onSelectionDragStart = useCallback(() => {
    // 👇 make dragging a selection undoable
    takeSnapshot();
  }, [takeSnapshot]);

  const onNodesDelete = useCallback(
    (deleted) => {
      takeSnapshot()
      setEdges(
        deleted.reduce((acc, node) => {
          const incomers = getIncomers(node, nodes, edges);
          const outgoers = getOutgoers(node, nodes, edges);
          const connectedEdges = getConnectedEdges([node], edges);

          const remainingEdges = acc.filter((edge) => !connectedEdges.includes(edge));

          const createdEdges = incomers.flatMap(({ id: source }) =>
            outgoers.map(({ id: target }) => ({ id: `${source}->${target}`, source, target }))
          );

          return [...remainingEdges, ...createdEdges];
        }, edges)
      );
    },
    [nodes, edges,takeSnapshot]
  );

  const onEdgesDelete = useCallback(() => {
    // 👇 make deleting edges undoable
    takeSnapshot();
  }, [takeSnapshot]);


  const addChildNode = useCallback(
    (parentNodeId) => {
      const childNodeId = Date.now();
      const childNode = {
        id: childNodeId,
        data: { label: `Node ${nodes.length + 1}` },
        position: { x: 0, y: 0 },
        style: { opacity: 0 },
      };
      const connectingEdge = {
        id: `${parentNodeId}->${childNodeId}`,
        source: parentNodeId,
        target: childNodeId,
        type: 'floating',
        style: { opacity: 0 },
      };

      setNodes((nodes) => nodes.concat([childNode]));
      setEdges((edges) => edges.concat([connectingEdge]));
    },
    [setNodes, setEdges, nodes.length]
  );

  const onNodeClick = useCallback(
    (_, node) => {
      // console.log(_,node)
      addChildNode(node.id);
    },
    [addChildNode]
  );

  const onConnect = useCallback(
    (connection) => {
      takeSnapshot();
      setEdges((eds) => addEdge(connection, eds))
    },
    [setEdges]
  );

  useEffect(() => {
    fitView();
  }, [nodes?.length]);

  return (
    <ReactFlow
      nodes={nodes}
      edges={edges}
      onNodesChange={onNodesChange}
      onEdgesChange={onEdgesChange}
      onConnect={onConnect}
      // onNodeClick={onNodeClick}
      nodesDraggable={true}
      // nodesFocusable={(e)=>console.log(e,'ini nodes focusable')}
      // defaultEdgeOptions={defaultEdgeOptions}
      // connectionLineType={ConnectionLineType.SmoothStep}
      proOptions={proOptions}
      zoomOnDoubleClick={false}
      edgeTypes={edgeTypes}
      nodeTypes={nodeTypes}
      onNodeDragStart={onNodeDragStart}
      onSelectionDragStart={onSelectionDragStart}
      onNodesDelete={onNodesDelete}
      onEdgesDelete={onEdgesDelete}
      // onNodeMouseEnter={(e)=>console.log(e)}
      fitView
    >
      <Panel position="top-left"><MindMapTopLeft/></Panel>
      <Panel position="bottom-center"><MindMapBottomCenter/></Panel>
      <Panel position="top-right"><MindMapTopRight undo={undo} redo={redo}/></Panel>
      <Controls/>

    </ReactFlow>
  );
}

const MindMapAutoLayout = () => {
  return (
    <ReactFlowProvider>
      <Leva hidden={true} />
      <div style={{ width: '100vw', height: '100vh' }}>
        <ReactFlowAutoLayout />
      </div>
    </ReactFlowProvider>
  );
};

export default MindMapAutoLayout;