// import { CheckIcon, ChevronLeftIcon, ChevronRightIcon, EmailIcon, SearchIcon } from "@chakra-ui/icons"
import { Avatar, Box, Button, HStack, IconButton, useColorMode } from '@chakra-ui/react'
import { useEffect } from 'react'
import { FcIdea, FcNoIdea, FcRedo, FcSearch, FcShare, FcUndo } from 'react-icons/fc'
import React from 'react'
import PropTypes from 'prop-types';



export const MindMapTopRight = (props) => {
  const { colorMode, toggleColorMode } = useColorMode()
  MindMapTopRight.propTypes = {
    // ...
    undo: PropTypes.func.isRequired,
    redo: PropTypes.func.isRequired,
  };

  useEffect(() => {

  },[colorMode])
  return(
    <HStack bgColor='white' p='2' borderRadius='full' shadow='base'>
      <FcSearch />
      <FcUndo onClick={() => props.undo()}/>
      <FcRedo onClick={() => props.redo()}/>
        
      {/* <Button onClick={toggleColorMode}>
        Toggle {colorMode === 'light' ? 'Dark' : 'Light'}
      </Button> */}
      {colorMode==='light?'?
        <FcIdea onClick={toggleColorMode}/> 
        :
        <FcNoIdea onClick={toggleColorMode}/>
      }
      <FcShare/>
      <Avatar size='sm' />
      <Button size='xs' borderRadius='full' colorScheme='green'>Save</Button>
    </HStack>
  )
}